.overlay_menu_wrapper {
    background-color: #FAFAFA;
    max-width: 300px;
    width: 100%;
    padding: 6px 8px;
}


.userContent_container:hover{
    scale: 1.04;
    border: 1px solid rgb(48, 110, 204) !important
}

.img_height_width{
    width: 90vw !important;
}

.img-div{
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    height: 100vh !important;
  }

.overlay_menu_wrapper .offcanvas-body {
    padding-right: 21px;
    padding-top: 25px;
    background-color: #FAFAFA;
}

.overlay_menu_link_wrap {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content:flex-start;
    background: var(--Background-offwhite, #FAFAFA);
}
.logout {
    display: flex;
    justify-content: flex-end;
    padding: 21px;
    padding-bottom: 10vh;
}

.overlay_menu_link_wrap ul {
    margin: 0;
    padding: 0;
    list-style: none;
}

.overlay_menu_link_wrap a {
    text-decoration: none;
    color: var(--BlBlack, #060E1E);
    text-align: right;
    font-family: 'Montserrat';
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    transition: all 0.3s ease;
    padding: 14px;
    border-radius: 8px;
}

.overlay_menu_link_wrap a:hover {
    color: #006400;
    box-shadow: 1px 1px 1px 0px rgba(134, 134, 134, 0.25), -1px -1px 1px 0px #FFF;}

.overlay_menu_link_wrap ul li:not(:last-child) {
    margin-bottom: 3.65vh;
}


.PM_logOut_btn {
    border: 0;
    border-radius: 9px; 
    padding: 0.5rem; 
    color: rgb(0, 0, 0); 
    background-color: transparent; 
    font-size: 18px; 
    transition: all 0.3s ease; 
}

.PM_logOut_btn:hover {
    background-color: transparent; 
    color: #006400;
    box-shadow: -1px -1px 1px 0px #FFF, 1px 1px 1px 0px rgba(134, 134, 134, 0.25); /* Box shadow similar to <a> tags */
}


.PM_credit_wrapp {
    background-color: #FAFAFA;
    display: grid;
    grid-template-columns: 50% auto;
    grid-gap: 15px;
    border-radius: 9px;
}

.PM_credit_wrapp .plan_name {
    line-height: 1.1;
}

.PM_credit_wrapp .plan_name strong {
    display: block;
}

.pm_profile {
    display: flex;
}

.pm_username {
    margin-left: 1.5rem;
    max-width: 24px;
    width: 100%;
    color: #006400;
}


.credit_and_Icon {
    display: flex;
    align-items: baseline;
    gap: 10px;
    width: fit-content;
}
.credit_and_Icon:first-of-type {
    align-items: center;
}
.credit_and_Icon .pm_credit {
    font-weight: 700;
    font-size: 23px;
    color: var(--color-active);
}

.credit_and_Icon .pm_credit.pm_credit_lite {
    font-weight: 400;
    font-size: 18px;
}

.PM_box_left .moreCreditTime .creditTimeCount {
    display: flex;
    gap: 10px;
    font-size: 16px;
}

.PM_box_left .moreCreditTime {
    line-height: 1.2;
    color: var(--color-bg-gray);
    font-size: 12px;
    margin-top: 20px;
}

.PM_box_left .upComingCredit {
    color: #000;

}
.profile-style a{
    display: flex;
    align-items: center; 
    justify-content: center;
    width: 175px !important;
    height: 48px !important;
}
.profile_hamburger {
    background-color: transparent;
    border: 0;
    color: initial;
    display: flex;
    align-items: center;
    gap: 15px;
    top: 8px !important;
    right: 1.5vw !important;
    padding: 0;
    transition: all 0.5s ease;
    z-index: 100;
}

.profile_hamburger:active,
.profile_hamburger:hover {
    background-color: transparent;
    color: initial;
}

.profile_hamburger.btn:first-child:active {
    background-color: transparent;
}

.profile_name {
    border-radius: 8px;
    background: #FAFAFA;;
    /* box-shadow: -1px -1px 1px 0px #FFF, 1px 1px 1px 0px rgba(134, 134, 134, 0.25); */
    color: var(--color-active);
    text-align: center;
    /* font-family: 'Roboto Slab', 'Blinker', sans-serif; */
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 1;
    width: 46px;
    height: 46px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.4s ease;
    color: #006400;
}

.profile_name:hover{
    background-color: #FFFFFF;
}

.profile_hamburger:hover .profile_name {
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.newPassBtn {
    border: 5px solid #0184d7;

}

.log_title_reset {
    color: #000;
    font-size: 54px;
    font-style: normal;
    font-weight: 600;
    line-height: 100%;
    letter-spacing: -0.605px;
}

.log_sub_reset_pass {
    font-size: 23px;
    font-weight: 300;
    letter-spacing: -0.352px;
    display: block;
}

.line-through {
    position: relative;
    text-decoration: line-through;
    color: gray
    
}
  
.line-through::before {
    content: '';
    position: absolute;
    left: 0;
    top: 65%;
    width: 100%;
    height: 2px; 
    background: #000; 
    transform: rotate(-45deg);
    transform-origin: 0 0;
 }


 .icon-space {
    margin-left: 1.5rem; 
  }

.profile-btn a {
    width: 154px;
    height: 48px;
  }

  .profile-btn a span{
    white-space: nowrap;
  }
  .profile-name {
    margin-bottom: 0;
  }
  
  .plan_name {
    margin-top: 1.5rem;
    text-align: right;
    border-right: 2px solid rgb(115, 115, 238); 
    padding-right: 10px; 
    margin-right: 1rem
  }
  .plan_name strong {
    margin-top: 1rem;
    margin-right: 1.5rem;
    display: block;
  }


 .icon-space {
    margin-left: 22px;
    max-width: 24px;
    width: 100%;
  }

  .plan_name {
    margin-top: 1.5rem;
    text-align: right;
    border-right: 2px solid rgb(115, 115, 238); 
    padding-right: 10px; 
    margin-right: 1rem
  }
  .plan_name strong {
    margin-top: 1rem;
    margin-right: 1.5rem;
    display: block;
  }
  
  .plan_name span {
    margin-right:1.5rem;
    display: block; 
  }
